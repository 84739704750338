import React, { useState } from "react";
import { FeedContainer, ImageModal } from "./News.elements";
import { Button, FeedItem, Modal } from "../../components";
import { NewsData } from "./News.data";

const News = () => {
  const [news] = useState(NewsData);
  const [modalImage, setModalImage] = useState<string>(
    "images/flyer_12042025.jpeg"
  );
  const [showModal, setShowModal] = useState<boolean>(true);
  const [loadAll, setLoadAll] = useState<boolean>(false);

  const handleOpenImageInModal = (e: any) => {
    setModalImage(e.target.src);
    setShowModal(true);
  };

  return (
    <div className="text-white flex flex-col justify-center items-center pt-3">
      <h3>NOVIDADES:</h3>
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          isOpen={true}
          children={<ImageModal src={modalImage} alt="Band picture" />}
        />
      )}
      {news &&
        (loadAll ? news : news.slice(0, 5)).map((item) => {
          return <FeedItem {...item} />;
        })}
      {!loadAll && (
        <div className=" w-full flex justify-center px-6 my-3">
          <Button className="w-full" onClick={() => setLoadAll(true)}>
            CARREGAR MAIS NOVIDADES
          </Button>
        </div>
      )}
    </div>
  );
};

export default News;
