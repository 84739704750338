import React, { useState } from "react";

import styled from "styled-components";
import { NewsData } from "../News/News.data";
import { Modal } from "../../components";
import { ImageModal } from "../News/News.elements";

export const Container = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  max-width: 300px;
  font-size: 10pt;

  @media (max-width: 640px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const GigContainer = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  text-align: center;
  border: 1px solid white;
  padding: 1rem;

  @media (max-width: 640px) {
    width: 100%;
    max-width: 270px;
  }
`;

export const ImageGig = styled.img`
  width: 100%;
  height: 100%;
  margin: 10px 0px;
  cursor: pointer;
`;

const Shows = () => {
  const [gigs] = useState(NewsData.filter((item) => item.gig));
  const [modalImage, setModalImage] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const onContainerClick = (imageUrl: string) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  return (
    <div className="text-white flex flex-col justify-center items-center">
      <h3 className="pt-3 pb-2">SHOWS:</h3>
      <Container>
        {gigs.map((news, i) => (
          <GigContainer
            title="Clique para mais detalhes"
            key={i}
            onClick={() => onContainerClick(news.gig?.flyerUrl!)}
          >
            <b>
              {news.gig?.dayOfWeek}, {news.gig?.date}
            </b>
            <ImageGig src={news.gig?.flyerUrl} alt="flyer" />
            <b>
              {news.gig?.city} - {news.gig?.state} BRASIL
            </b>
            <p>{news.gig?.festivalName}</p>
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded my-1"
              onClick={() => onContainerClick(news.gig?.flyerUrl!)}
            >
              + DETALHES
            </button>
          </GigContainer>
        ))}
      </Container>

      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          isOpen={true}
          children={<ImageModal src={modalImage} alt="Flyer" />}
        />
      )}
    </div>
  );
};

export default Shows;
