import React from "react";
import { Image } from "../../components";

interface ShowInfo {
  date: string;
  dayOfWeek: string;
  city: string;
  flyerUrl: string;
  state: string;
  festivalName: string;
}

interface Feed {
  title?: string;
  text?: string;
  youtubeLink?: string;
  date?: string;
  bodyContent?: JSX.Element;
  gig?: ShowInfo;
}

const NewsData: Feed[] = [
  {
    title: "SHOW DIA 12/04/2025 EM SÃO PAULO!!",
    text: "",
    date: "07/03/2025",
    gig: {
      date: "12/04/2025",
      dayOfWeek: "Sábado",
      city: "São Paulo",
      state: "SP",
      flyerUrl: "images/flyer_12042025.jpeg",
      festivalName: "SÃO PAULO EM CHAMAS",
    },
    bodyContent: (
      <div className="flex flex-col">
        <Image imagePath="images/flyer_12042025.jpeg" alt="Flyer Event" />
        <div>
          🔥🔥🔥 SÃO PAULO EM CHAMAS 🔥🔥🔥
          <br />
          <br /> LUXÚRIA DE LILLITH - GO
          <br /> SACRIFIX - SP
          <br /> VISMAL - SP
          <br />
          <br /> DATA: 12 DE ABRIL
          <br /> LOCAL: RUA DR CÂNDIDO ESPINHEIRA - 315 - PERDIZES - SP
          <br /> HORÁRIO: 17 HORAS
          <br /> INGRESSOS: ANTECIPADOS R$ 30,00 / PORTARIA: R$ 50,00
          <br /> SEREMOS A PRIMEIRA BANDA, COMPAREÇAM!!! 🔥🔥🔥
        </div>
      </div>
    ),
  },
  {
    title: "",
    text: "CHAPTER OF HORROR PIC",
    date: "08/02/2025",
    bodyContent: (
      <div className="flex flex-col">
        <Image imagePath="images/vismal_2025.webp" alt="Band picture" />
        <p>
          <br />
          <strong>Marlon Magog </strong>
          <i>(bass, vocals)</i>
          <br />
          <strong>Eduardo Auriemi </strong>
          <i>(drums)</i>
          <br />
          <strong>
            <b>Bruno Alvarez </b>
          </strong>
          <i>(guitars, vocals)</i>
          <br />
          <strong>
            <b>André Koveiro </b>
          </strong>
          <i>(guitars)</i>
          <br />
        </p>
      </div>
    ),
  },
  {
    title: "SHOW DIA 08/02/2025 EM OSASCO-SP!!",
    text: "",
    gig: {
      date: "08/02/2025",
      dayOfWeek: "Sábado",
      city: "Osasco",
      state: "SP",
      flyerUrl: "images/flyer_08022025.jpeg",
      festivalName: "CHAPTER OF HORROR",
    },
    date: "23/12/2024",
    bodyContent: (
      <div className="flex flex-col">
        <Image imagePath="images/flyer_08022025.jpeg" alt="Flyer Event" />
        <div>
          CHAPTER OF HORROR:
          <br /> ⚔️ SACRIFIX ⚔️ <br /> ⚔️ HIERARCHICAL PUNISHEMENT ⚔️
          <br />
          ⚔️ CASCAVEL ⚔️
          <br /> ⚔️ VISMAL ⚔️
          <br /> DATA: 8 DE FEVEREIRO <br /> LOCAL:ESTÚDIO DO LAU( RUA
          ALVINLÂNDIA , 277, OSASCO -SP)
          <br /> HORÁRIO: 19 HORAS <br /> ENTRADA: 15 REAIS COMPAREÇAM 🔥🔥
        </div>
      </div>
    ),
  },
  {
    title: "Novo single!!",
    text: "Ouça agora o nosso novo single Humans For Sale!!!",
    youtubeLink:
      "https://www.youtube.com/embed/5Xaq5yoaqkE?si=S4eA-NzXHT0q07WJ",
    date: "21/09/2024",
  },
  {
    title: "SHOW GRÁTIS DIA 22/09/2024 EM COTIA-SP!!",
    text: "Cotia e galera da região, nos aguardem! 22 de setembro vai ser mais um dia animal! Esperamos todos vocês!",
    gig: {
      date: "22/09/2024",
      dayOfWeek: "Domingo",
      city: "Cotia",
      state: "SP",
      flyerUrl: "images/flyer_22092024.jpeg",
      festivalName: "COTIA ROCK FEST",
    },
    date: "26/07/2024",
    bodyContent: (
      <>
        <Image imagePath="images/flyer_22092024.jpeg" alt="Flyer Event" />
      </>
    ),
  },
  {
    title: "Nova formação!",
    text: "Temos um novo guitarrista, André Koveiro!",
    date: "25/06/2024",
    bodyContent: (
      <div className="flex flex-col">
        <Image imagePath="images/new_formation_2024.webp" />
        <p>
          <strong>Marlon Magog </strong>
          <i>(bass)</i>
          <br />
          <strong>
            <b>André Koveiro </b>
          </strong>
          <i>(guitars)</i>
          <br />
          <strong>Eduardo Auriemi </strong>
          <i>(drums)</i>
          <br />
          <strong>
            <b>Bruno Alvarez </b>
          </strong>
          <i>(guitars, vocals)</i>
          <br />
        </p>
      </div>
    ),
  },
  {
    title: "Camisetas!",
    text: "Compre agora nossas camisetas pelo nosso parceiro mojostamp.com.br",
    date: "07/08/2023",
    bodyContent: (
      <div className="flex flex-col">
        <a
          href="https://mojostamp.com.br/camiseta-vismal-1d6ch"
          target="_blank"
          rel="noreferrer"
        >
          https://mojostamp.com.br/camiseta-vismal-1d6ch
        </a>
        <Image imagePath="images/banda_camisetas.jpeg" alt="Flyer Event" />
      </div>
    ),
  },
  {
    title: "Video do último show!",
    text: "VismaL ao vivo no festival Abaddon Metal Fest 1 que aconteceu no Abaddon Rock Bar na cidade de Cotia em 24 de Junho de 2023",
    youtubeLink: "https://www.youtube.com/embed/rUC_usJfpFo",
    date: "29/06/2023",
  },
  {
    title: "SHOW GRÁTIS DIA 24/06/2023 EM COTIA-SP!!",
    gig: {
      date: "24/06/2023",
      dayOfWeek: "Sábado",
      city: "Cotia",
      flyerUrl: "images/flyer_24062023.jpeg",
      state: "SP",
      festivalName: "ABADDON METAL FEST",
    },
    text: "Depois de 12 anos longe dos palcos, estamos de volta!\nO ABADDON METAL FEST acontece no @abaddonrockbar no dia 24 de Junho em Cotia (SP). Nos apresentaremos ao lado dos irmãos das bandas @violenthateoficial e @agonyhell_oficial.\nEntrada Gratuita!\nVenham conferir essa noite de verdadeira destruição",
    date: "22/05/2023",
    bodyContent: (
      <Image imagePath="images/flyer_24062023.jpeg" alt="Flyer Event" />
    ),
  },
  {
    title: "Nova formação!!",
    text: "Estamos de volta!!!",
    date: "12/05/2023",
    bodyContent: (
      <div className="flex flex-col">
        <Image imagePath="images/band_2023.jpg" alt="Band picture" />
        <p>
          <strong>Eduardo Auriemi </strong>
          <i>(drums)</i>
          <br />
          <strong>
            <b>Bruno Alvarez </b>
          </strong>
          <i>(guitars, vocals)</i>
          <br />
          <strong>Marlon Magog </strong>
          <i>(bass)</i>
          <br />
          <strong>
            <b>Silvio Lelis </b>
          </strong>
          <i>(guitars)</i>
          <br />
        </p>
      </div>
    ),
  },
  {
    title: "Participação em podcast!",
    text: "O vocal Bruno Alvarez esteve no Round Table Forever Podcast, veja a entrevista na íntegra:",
    youtubeLink: "https://www.youtube.com/embed/1wP9cDP737U",
    date: "15/03/2023",
  },
  {
    title: "Novo video!",
    text: "Assista agora ao nosso video oficial INNER SUFFERING!!!",
    youtubeLink: "https://www.youtube.com/embed/cZEswpksrqU",
    date: "16/02/2023",
  },
  {
    title: "Novo video!",
    text: "Assista agora ao nosso video oficial DAWN OF THE DEAD!!!",
    youtubeLink: "https://www.youtube.com/embed/oO3Pc69Higo",
    date: "02/12/2022",
  },
  {
    title: "Novo álbum!!",
    text: "Ouça agora o nosso novo álbum INNER SUFFERING!!!",
    youtubeLink: "https://www.youtube.com/embed/kshWRfAcyUU",
    date: "11/11/2022",
  },
];

export { NewsData, Feed };
