import React, { ButtonHTMLAttributes, ReactNode } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  className,
  ...props
}) => {
  return (
    <button
      className={`bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded my-1 ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
